import React from "react";

import { Container, Icon, Button, Text } from "@atoms";
import { SocialLinks } from "@molecules";
import { AppLink } from "@base";
import dayjs from "dayjs";

const LakotaFooter = ({ nav }) => {
  return (
    <div className="mt-auto w-full bg-brown py-8 px-2 sm:py-12 sm:px-0">
      <Container>
        <div className="sm:hidden">
          <Icon name="iconLakotaInverse" className="h-auto w-12" />
        </div>
        <div className="flex flex-wrap md:flex-nowrap md:justify-between">
          {/* logo */}
          <div className="order-2 mb-6 mr-6 flex-shrink-0 text-xs text-red sm:order-1">
            <div className="hidden sm:block">
              <Icon name="iconLakotaInverse" className="h-auto w-12" />
            </div>

            {/* details */}
            <div className="my-4">
              {process.env.GATSBY_CRAFT_SITE_HANDLE === "lakota" && (
                <Text variant="body--small-tight">
                  {"547 South 7th Street #149<br>Bismarck, ND 58504-5859"}
                </Text>
              )}
              {process.env.GATSBY_CRAFT_SITE_HANDLE !== "lakota" && (
                <AppLink
                  className="text-sm font-bold uppercase"
                  to="https://www.lakotalaw.org"
                >
                  lakotalaw.org
                </AppLink>
              )}
            </div>

            {/* copyright */}
            <div className="my-4">
              &copy; {dayjs().format("YYYY")} Lakota People&apos;s Law Project
            </div>
            <div className="my-4 font-bold uppercase">
              {/* TODO: Pull from globals */}
              <AppLink
                className="text-xs"
                to="https://action.lakotalaw.org/privacy"
              >
                Privacy Policy
              </AppLink>
            </div>
          </div>
          {/* nav */}
          <div className="order-1 mt-6 max-w-xl sm:order-2 sm:mt-0">
            {/* mobile */}
            <div className="-mx-6 -mb-4 flex flex-wrap justify-end text-xs font-bold text-white sm:hidden">
              {nav &&
                nav.map(item => {
                  const { uid, type, link } = item;
                  switch (type) {
                    case "smallLink":
                      return (
                        <div
                          key={uid}
                          className="order-4 mb-6 w-full px-6 text-red"
                        >
                          <AppLink to={link.link}>{link.text}</AppLink>
                        </div>
                      );
                    case "largeLink":
                      return (
                        <div
                          key={uid}
                          className="order-2 mb-6 w-full px-6 uppercase"
                        >
                          <AppLink to={link.link}>{link.text}</AppLink>
                        </div>
                      );
                    case "button":
                      return (
                        <div key={uid} className="order-3 mb-6 w-full px-6">
                          <Button size="xs" to={link.link}>
                            {link.text}
                          </Button>
                        </div>
                      );
                    default:
                      return null;
                  }
                })}

              <div className="order-1 mb-6 w-full px-6 sm:w-1/3">
                <SocialLinks />
              </div>
            </div>
            {/* tablet + desktop */}
            <div className="-mx-6 hidden flex-wrap justify-end text-xxs font-bold text-white sm:flex">
              {nav &&
                nav.map(item => {
                  const { uid, type, link } = item;
                  switch (type) {
                    case "smallLink":
                      return (
                        <div
                          key={uid}
                          className="mb-6 w-1/2 px-6 text-red sm:w-1/3"
                        >
                          <AppLink to={link.link}>{link.text}</AppLink>
                        </div>
                      );
                    case "largeLink":
                      return (
                        <div
                          key={uid}
                          className="mb-6 w-1/2 px-6 uppercase sm:w-1/3"
                        >
                          <AppLink to={link.link}>{link.text}</AppLink>
                        </div>
                      );
                    case "button":
                      return (
                        <div key={uid} className="mb-6 w-1/2 px-6 sm:w-1/3">
                          <Button size="xs" to={link.link}>
                            {link.text}
                          </Button>
                        </div>
                      );
                    default:
                      return null;
                  }
                })}

              <div className="mb-6 w-full px-6 sm:w-1/3">
                <SocialLinks />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

LakotaFooter.defaultProps = {};

export default LakotaFooter;
